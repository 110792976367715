// helper functions
// function csrFetch
import PACKAGE from '../package.json';


/**
 * verifies if number, falls back to 0, then casts to inr locale string 
 * */
function numToINR(num) {
    return (parseFloat(num)?parseFloat(num):0).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
}


function decodeEntities (str) {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('textarea');
  
    if (str && typeof str === 'string') {
        element.innerHTML = str;
        str = element.value;
        element.value = '';
    }
    

    return str;
 
}


function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
  
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    let copyResult = false
  
    try {
      var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        copyResult = true
      // console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        copyResult = false
      console.error("Fallback: Unable to copy", err);
    }
  
    document.body.removeChild(textArea);
    return copyResult
}

function copyTextToClipboard(text) {
    return new Promise((resolve,reject) => {
        if (!navigator.clipboard) {
            // console.log('copying with fallback method');
            let res = fallbackCopyTextToClipboard(text);
            if (res) {
                resolve();
            } else {
                reject()
            }
        }
        navigator.clipboard.writeText(text)
            .then(() => {
                resolve();
            }, () => {
                reject();
            })
        
    });
}

function csrfPost(url, body) {
    return new Promise(resolve => {
        fetch(url, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                [PACKAGE.siteConfig.csrf_header]:document.querySelector('meta[name="' +PACKAGE.siteConfig.csrf_header + '"]').content
            },
            body: JSON.stringify(body)
        })
            .then(resultBody => resultBody.json())
            .then(result => {
                // window.csrf_hash = result[window.csrft];
                document.querySelector('meta[name="' +PACKAGE.siteConfig.csrf_header + '"]').content = result[PACKAGE.siteConfig.csrf_header];
                delete result[PACKAGE.siteConfig.csrf_header];
                resolve(result);
            })
    });
    
}

export{numToINR, decodeEntities, copyTextToClipboard,csrfPost}