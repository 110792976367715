import $ from "jquery"
// import jQuery from "jquery"
import 'select2'
import * as bootstrap from 'bootstrap'
import DataTable from "datatables.net-bs5";
import { numToINR } from "./functions";
// import {Tooltip} from 'bootstrap'
window.$ = $;
// window.jQuery = $;
window.bootstrap = bootstrap
// let mymodal = new Modal('#myModal')
// document.querySelectorAll('#myModal')
//   .forEach(modal => {
//   })

// tooltips
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))


// select2
$('.select2').select2({
    theme: 'bootstrap-5',
    width: '100%',
    dropdownAutoWidth:true,
})

// datatables
const dtables = [...document.querySelectorAll('[data-datatablize]')].map((tbl) => {
    let opts = {
        autoWidth:false,
        dom: `<"row g-2"
            <"col-md-6 d-flex"l><"col-md-6 d-flex justify-content-end"f>
            <"col-12"
                <"table-responsive"tr>
            >
            <"col-md-6"i><"col-md-6 d-flex justify-content-end"p>
        >`
    }
    return new DataTable(tbl,opts)
})
window.dtables = dtables


// ajax searches
const ajaxSearchFields = $('[data-ajax-search]');
if (ajaxSearchFields.length) {
    ajaxSearchFields.each((index, searchField) => {
        $(searchField).on('input', () => {
            let resultWrapSelector = searchField.dataset.resultsWrapper
            let resultTemplateSelector = searchField.dataset.resultTemplate
            let spinnerTemplate = searchField.dataset.spinnerTemplate
            let emptyResultTemplate = searchField.dataset.emptyResultsTemplate
            let searchURL = searchField.dataset.searchUrl
            let searchParam = searchField.dataset.searchParam
            let resultWrap = $(resultWrapSelector)
            resultWrap.empty()
            // send ajax request to given url
            if (searchField.value.length) {
                if($(spinnerTemplate).length) {
                    resultWrap.append($($(spinnerTemplate).html()))
                }
                $.ajax({
                    url: searchURL + '?' + searchParam + '=' + searchField.value,
                    dataType: 'json',
                    success(data) {
                       resultWrap.empty()
                        let resultTemplate = $(resultTemplateSelector)
                        if (data.length == 0 && $(emptyResultTemplate).length) {
                            resultWrap.append($($(emptyResultTemplate).html()))
                        
                            
                        }
                        data.forEach(product => {
                            let newResultHTML = resultTemplate.html()
                                .replace('{{product_name}}', product.name)
                                .replace('{{unit_price}}', numToINR(product.unit_price))
                                .replace('{{unit_name}}',product.unit_name)
                                .replace('{{product_id}}', product.id)
                            resultWrap.append($(newResultHTML))
                        })
                    }
                })
            }
        })
    })
}






// window.addToCart = addToCart
window.numToINR = numToINR